<template>
  <div>
    <register-emisor ref="RegisterEmisor" />
    <show-emisor ref="ShowEmisor" />
  </div>
</template>
<script>
  export default {
    components: {
      RegisterEmisor: () => import('./components/emisor/RegisterEmisor'),
      ShowEmisor: () => import('./components/emisor/ShowEmisor'),
    },
  }
</script>
